<template>
  <div id="zhilian">
    <!-- 顶部布局 -->
    <div class="top-layout">
      <Head></Head>
    </div>

    <div class="content">
      <div class="middle-layout">
        <EnterViewVue></EnterViewVue>
        <introduce></introduce>
        <ProductContent></ProductContent>
        <ThreeBasics></ThreeBasics>
        <ClientVue></ClientVue>
        <BottomTitleVue></BottomTitleVue>
      </div>

      <div class="bottom-layout">
        <Bottom></Bottom>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "./components/Head.vue";
import Bottom from "./components/Bottom.vue";
import introduce from "./components/introduce.vue";
import ProductContent from "./components/ProductContent.vue";
import EnterViewVue from "./components/EnterView.vue";
import ThreeBasics from "./components/ThreeBasics.vue";
import ClientVue from "./components/Client.vue";
import BottomTitleVue from "./components/BottomTitle.vue";
export default {
  components: {
    Head,
    Bottom,
    EnterViewVue,
    introduce,
    ProductContent,
    ThreeBasics,
    ClientVue,
    BottomTitleVue,
  },
};
</script>

<style lang="less" scoped>
.zhilian {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.top-layout {
  height: 64px;
}

.middle-layout {
  flex-grow: 1;
}
.content {
  width: 100vw;
}
.bottom-layout {
  bottom: 0;
  width: 100%;
  height: 240px;
  background: #333333;
}
</style>
