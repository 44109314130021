// 导入Vue和Vue Router
import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import ProductPage from "../views/ProductPage.vue";
import AboutUsPage from "../views/AboutUsPage.vue";
import ClientPage from "../views/ClientPage.vue";
// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/Home",
    },
    {
      path: "/Home",
      name: "Home",
      component: HomePage,
    },
    {
      path: "/Product",
      name: "Product",
      component: ProductPage,
    },
    {
      path: "/AboutUs",
      name: "AboutUs",
      component: AboutUsPage,
    },
    {
      path: "/Client",
      name: "Client",
      component: ClientPage,
    },
  ],
});

// 导出路由实例
export default router;
