<template>
  <div>
    <Head></Head>
    <div class="content">
      <introduce></introduce>
    </div>
    <div class="bottom-layout">
      <Bottom></Bottom>
    </div>
  </div>
</template>

<script>
import Head from "./components/Head.vue";
import Bottom from "./components/Bottom.vue";
import introduce from "./components/introduce.vue";
export default {
  components: {
    Head,
    Bottom,
    introduce,
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
}
.bottom-layout {
  // bottom: 0;
  width: 100%;
  height: 240px;
  background: #333333;
}

</style>
