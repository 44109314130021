<template>
  <div class="content">
    <div class="conDescribe">
      <p>3大基础底座，开启智能决策新时代</p>
      <span>智能AI赋能产品科技，洞悉未来发展，决策有理有据</span>
      <ul>
        <li>
          <span> 1.核心AI能力，赋能企业智慧决策</span>
          <p>智链平台运用先进的AI技术，为企业带来更深入的洞察和更高效的决策</p>
          <p>自动化完成经营分析任务，降低对专业财务人员的依赖。</p>
          <p>实时监控，随时获取最新经营状况报告，数据粒度更细。</p>
          <p>智能根据分析结果自动调整经营决策，提高效率和准确性。</p>
        </li>
        <li>
          <span> 2.SaaS+能力，灵活高效</span>
          <p>
            在涉及知产和企业经营数据分析的模块上，平台运用SaaS+技术构建功能，降低维护成本，提高工作效率和协作能力。
          </p>
          <p>无需安装和维护软件，通过互联网使用平台。</p>
          <p>先进的安全技术和措施，保障数据的安全性和隐私性。</p>
          <p>多用户支持特性，协同工作，提高效率。</p>
        </li>
        <li>
          <span> 3. AI法务助手，专业高效</span>
          <p>
            智链自研AI法务助手，精准处理法律事务，解析复杂的法律条文和案例。
          </p>
          <p>运用大规模预训练模型的能力，实现精准的海量法律领域语料库训练。</p>
          <p>能够理解并处理日常和复杂的法律事务，如合同审查、法律研究等。</p>
          <p>进行法律文本分析，提供更深入的法律洞察。</p>
        </li>
      </ul>
    </div>
    <div class="divcon">
      <!-- <div style="width: 1000px"> -->
      <el-carousel :interval="3000" type="card" :arrow="'never'">
        <el-carousel-item v-for="item in imageSources" :key="item">
          <el-image
            :src="require(`@/assets/${item}`)"
            alt="Image"
            class="Image"
          />
        </el-carousel-item>
      </el-carousel>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imageSources: ["lunbo1.png", "lunbo2.png", "lunbo3.png"],
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  height: 480px;
  width: 100%;
  background-color: #fafafa;
  display: flex;
  .divcon {
    width: 512px;
    height: 400px;
    padding: 3% 3% 2% 5%;
  }
  .Image {
    width: 100%;
    height: 299.9px;
  }
  .conDescribe {
    // width: 100%;
    padding: 3% 2% 0% 10%;

    P {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: #000;
    }
    span {
      padding: 1% 0% 1% 0%;
      display: inline-block;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #999999;
    }
    ul {
      height: 220px;
      width: 590px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px; /* WebKit */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #72d499; /* WebKit */
        border-radius: 4px; /* 圆角 */
      }

      &::-webkit-scrollbar-track {
        background-color: #f0fff3; /* WebKit */
        border-radius: 4px; /* 圆角 */
      }
      li {
        width: 576px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;
        letter-spacing: 2px;
      }
    }
  }
}
</style>
