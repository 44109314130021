<template>
  <div>
    <Head></Head>
    <div class="content">
      <!-- <video
        class="background-video"
        src="@/assets/about_bg.mp4"
        muted
        autoplay
        loop
      ></video> -->
      <div class="background-video-container">
        <video
          class="background-video"
          src="@/assets/about_bg.mp4"
          muted
          autoplay
          loop
        ></video>
      </div>
      <div class="jieshao">
        <div class="title">
          <span>智链</span>
          <img src="@/assets/Group 28.png" alt="" class="tubiao" />
        </div>
        <ul>
          <p>
            在当今科技迅猛发展的时代，专利创新成为企业不可或缺的核心竞争力。为了更好地支持企业专利管理的需求，我们引以为傲地推出一款颠覆性的专利管理平台，为您的知识产权保驾护航。
          </p>
          <li>平台亮点：</li>
          <li>
            1. 智能化管理：
            我们的专利管理平台基于先进的人工智能技术，实现对专利文件的智能化识别、分类和管理。通过自动化流程，极大地提高了专利审批和维护的效率，释放人力资源，让您更专注于创新。
          </li>
          <li>
            2. 统一视图：
            无论您的专利数量有多庞大，我们的平台提供了统一的、直观的管理视图。一键查询、排序和过滤，使您可以迅速了解专利状况，作出明智的战略决策。
          </li>
          <li>
            3. 协作平台：
            我们的平台不仅仅是专利管理工具，更是一处协作的平台。团队成员可以在平台上实时共享、讨论和编辑专利文件，实现团队协同创新，加速项目推进。
          </li>
          <li>
            4. 高级分析工具：
            通过内置的高级分析工具，我们的平台能够为您提供深度的数据洞察。分析专利趋势、竞争对手的动向，为您的业务发展提供科学依据。
          </li>
          <li>
            5. 安全保障：
            我们致力于为用户提供最高级别的数据安全保障，采用先进的加密技术，确保专利文件的保密性和完整性，让您安心使用。
          </li>
          <p>
            我们的专利管理平台不仅仅是一个工具，更是一项推动创新的引擎。在竞争激烈的市场中，选择我们，是选择了更高效、更智能、更安全的专利管理方案。让我们携手，共同打造一个充满创新活力的未来！
          </p>
        </ul>
      </div>
    </div>
    <div class="bottom-layout">
      <Bottom></Bottom>
    </div>
  </div>
</template>

<script>
import Head from "./components/Head.vue";
import Bottom from "./components/Bottom.vue";
export default {
  components: {
    Head,
    Bottom,
  },
};
</script>

<style lang="less" scoped>
.tubiao {
  width: 160px;
  height: 32px;
}
.content {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 64px - 240px);
  overflow: hidden;
  position: relative; /* 为了在移动端保持视频的纵横比例 */
  .background-video-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .background-video {
    position: absolute;
    top: 206px;
    left: 0;
    // width: 1440px;
    // height: 400px;
    width: 100%;
    height: 100%;
    object-fit: cover; /* 确保视频完全覆盖容器 */
    z-index: -1; /* 确保视频位于内容的底层 */
  }
  .jieshao {
    // width: 100%;
    // margin: 6% 11% 11% 25%;
    display: flex;
    flex-direction: column;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    // height: 100vh; /* 占满整个视口高度，使内容垂直居中 */
    padding: 20px; /* 根据需要调整内边距 */
    .title {
      // width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 2%;
      margin-top: 3%;
      span {
        margin-right: 10px;
        display: inline-block;
        width: 60px;
        padding-right: 0.5%;
        font-family: PingFang SC;
        font-size: 28px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #72d499;
      }
    }
    ul {
      padding: 10px;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      letter-spacing: 5px;
      li {
        padding: 0% 1% 0% 1%;
        width: 96%;
      }
      p {
        padding: 2% 1% 2% 1%;
        width: 96%;
      }
    }
  }
}
.bottom-layout {
  // bottom: 0;
  width: 100%;
  height: 240px;
  background: #333333;
}
</style>
