<template>
  <div class="con">
    <div class="conimg">
      <ul>
        <li><img src="@/assets/Group 91.png" alt="" /></li>
        <li><img src="@/assets/Group 92.png" alt="" /></li>
        <li><img src="@/assets/Group 93.png" alt="" /></li>
        <li><img src="@/assets/Group 94.png" alt="" /></li>
      </ul>
    </div>
    <div class="client">
      <div>
        <p class="clientevaluate">客户评价</p>
        <p class="clientevaluates">Customer evaluation</p>
      </div>
    </div>
    <div class="kehu">
      <ul>
        <li v-for="(customer, index) in customers" :key="index">
          <div class="kehutop">
            <div class="tX">
              <img :src="require(`@/assets/${customer.avatar}`)" alt="" />
            </div>
            <span class="name">{{ customer.name }}</span>
            <span class="title">{{ customer.title }}</span>
          </div>
          <p class="pinglu">{{ customer.comment }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customers: [
        {
          avatar: "Ellipse 2.png",
          name: "西斯科夫",
          title: "集团CEO",
          comment:
            "智链这款产品使我在监控公司专利状况提供了有效帮助，让我更加透明高效的实施管理。",
        },
        {
          avatar: "Ellipse 3.png",
          name: "莎莉",
          title: "企业法务人员",
          comment:
            "智链这款产品的「法务AI」功能很好的解决了我日常工作中对于专利相关的疑难问题。",
        },
        {
          avatar: "Ellipse 4.png",
          name: "詹姆士",
          title: "集团高管",
          comment:
            "使用了智链之后，公司对于专利的管理更为轻松了，只需要简单的处理就可以完成操作。",
        },
        {
          avatar: "Ellipse 1.png",
          name: "弗朗斯西",
          title: "企业员",
          comment:
            "智链是一款对于专利管理非常有帮助的平台，对于公司的专利管理有着巨大作用！",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.con {
  height: 576px;
  width: 100%;
  background-color: #ffffff;
  .conimg {
    margin: 2% 0% 2% 9.5%;
    width: 1208px;
    border-bottom: 1px solid #e5e5e5;
    ul {
      display: flex;
      li {
        padding: 2% 0% 2% 1%;
        img {
          width: 290px;
          height: 140px;
        }
      }
    }
  }
  .client {
    text-align: center;
    .clientevaluate {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #333333;
      padding-bottom: 10px;
    }
    .clientevaluates {
      //styleName: T3;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #999999;
    }
  }
  .kehu {
    margin-top: 2%;
    display: flex;
    ul {
      width: 1440px;
      height: 240px;
      overflow-x: auto;
      display: flex;
      &::-webkit-scrollbar {
        height: 4px; /* 调整滚动条高度，注意这里是 height 而不是 width */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #72d499; /* 滑块颜色 */
        border-radius: 4px; /* 圆角 */
      }

      &::-webkit-scrollbar-track {
        background-color: #f0fff3; /* 轨道颜色 */
        border-radius: 4px; /* 圆角 */
      }
      li {
        margin-left: 1%;
        // margin-right: 0.5%;
        width: 392px;
        height: 200px;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        flex: 0 0 auto;
        .kehutop {
          display: flex;
          align-items: center;
          padding: 8% 7% 8% 4%;
          line-height: 2%;
          .tX {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 1px solid #72d499;
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }
          .name {
            margin: 1% 3% 1% 2%;
            //styleName: T4;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #333333;
          }
          .title {
            //styleName: T4;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #999999;
          }
        }
        .pinglu {
          margin: 1% 0% 0% 5%;
          width: 344px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
}
</style>
