<template>
  <div class="banner">
    <div class="bannerdiv">
      <div>
        <span>启迪之星国知一站式服务平台</span>
        <!-- <img src="@/assets/Group.png" alt="" /> -->
      </div>
      <p>让创业成功更简单!</p>
      <!-- <button></button> -->
      <div
        class="video-container"
        @mouseenter="playVideo"
        @mouseleave="pauseVideo"
      >
        <!-- <a href="https://www.tusstarss.com/smartchain/#/login">
          <video ref="videoRef" muted loop src="@/assets/home_btn.mp4"></video>
          <div class="text-overlay">进入一站式服务平台</div>
        </a> -->
        <a href="https://test.tusstarss.com/smartchain/#/login">
          <video ref="videoRef" muted loop src="@/assets/home_btn.mp4"></video>
          <div class="text-overlay">进入一站式服务平台</div>
        </a>
      </div>
      <!-- <video autoplay muted loop src="@/assets/home_btn.mp4"></video>
      <div></div> -->
    </div>
    <div class="rightimg">
      <img src="@/assets/Group 81.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    playVideo() {
      this.$refs.videoRef.play();
    },
    pauseVideo() {
      this.$refs.videoRef.pause();
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 1440px;
  height: 420px;
  background-image: url("@/assets/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: space-between;
  .bannerdiv {
    padding: 112px 10px 12px 110px;
    div {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      span {
        padding-right: 20px;
        font-size: 28px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;
      }
      img {
        width: 160px;
        height: 32px;
      }
    }
    p {
      padding-bottom: 32px;
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: #333333;
    }
    .video-container {
      position: relative;
      border-radius: 10px; /* 圆角边框 */
      overflow: hidden;
      cursor: pointer;
    }

    video {
      width: 320px;
      height: 64px;
      border-radius: 32px;
    }

    .text-overlay {
      position: absolute;
      top: 50%;
      left: 43%;
      transform: translate(-52%, -53%);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #ffffff;
    }
  }
  .rightimg {
    margin-right: 148px;
    img {
      width: 500px;
      height: 500px;
    }
  }
}
</style>
