<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import "./style/style.less";
export default {};
</script>
