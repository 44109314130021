<template>
  <div class="con">
    <div class="condiv">
      <img src="@/assets/kk_area_img_bg.png" alt="" />
      <img class="img2" src="@/assets/kk_area_img_computer.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.con {
  height: 360px;
  background-color: #fafafa;
  display: flex;
  .condiv {
    position: relative;
    margin: 6% 2% 1% 10%;
    border-radius: 8px;
    width: 1208px;
    height: 240px;
    background: linear-gradient(90deg, #414345 0%, #232526 100%);
    img {
      width: 1208px;
      height: 240px;
    }
    .img2 {
      position: absolute;
      top: -80px;
      right: 20px;
      width: 500px;
      height: 320px;
    }
  }
}
</style>

