<template>
  <div>
    <Head></Head>
    <div class="content">
      
    </div>
    <div class="bottom-layout">
      <Bottom></Bottom>
    </div>
  </div>
</template>

<script>
import Head from "./components/Head.vue";
import Bottom from "./components/Bottom.vue";
export default {
  components: {
    Head,
    Bottom,
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: calc(100vh - 64px - 240px);
}
.bottom-layout {
  bottom: 0;
  width: 100%;
  height: 240px;
  background: #333333;
}
</style>
