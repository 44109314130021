<template>
  <div class="content">
    <div class="divcon">
      <!-- <div style="width: 1000px"> -->
      <el-carousel :interval="3000" type="card" :arrow="'never'">
        <el-carousel-item v-for="item in imageSources" :key="item">
          <el-image
            :src="require(`@/assets/${item}`)"
            alt="Image"
            class="Image"
          />
        </el-carousel-item>
      </el-carousel>
      <!-- </div> -->
    </div>
    <div class="conDescribe">
      <p>6大产品内容，打造智能化经营新模式</p>
      <span
        >国内首个一站式智能化经营解决与指导服务平台；智链在手，经营无忧</span
      >
      <ul>
        <li>
          <span>1. 智能化经营解决与指导服务</span>
          <p>
            智链致力于为中小企业主提供全面、一站式的科技服务。我们涵盖投融资、知识产权、财务分析、业务流程、法律咨询、人才培养、政策获取等方面，全方位服务，助力企业稳健发展。
          </p>
        </li>
        <li>
          <span> 2. 推动产业升级和科技创新</span>
          <p>
            智链运用人工智能、大数据等先进技术，帮助企业进行科技创新和产业升级。助推企业适应市场需求和行业变化，提升竞争力。
          </p>
        </li>
        <li>
          <span> 3. 保护知识产权，创新无忧</span>
          <p>
            专业的事交给专业的人，我们提供行业领先的知产服务，覆盖企业全生命周期，帮助企业维护自身的知识产权权益，确保企业的创新成果得到充分保护。
          </p>
        </li>
        <li>
          <span> 4. 投融资获取和多元化全方位企业服务</span>
          <p>
            融合启迪之星全球孵化体系能力，为企业提供投融资获取、多元化全方位企业服务等支持。我们帮助企业获得更多发展机会，实现更大的商业价值。
          </p>
        </li>
        <li>
          <span>5. 政策支持获取</span>
          <p>
            智链帮助企业了解并获得政府支持，为企业的发展提供有力保障。我们为企业提供政策支持获取的相关服务，让您的事业更加顺利。
          </p>
        </li>
        <li>
          <span>6. 企业获取政策支持的解决方案</span>
          <p>
            智链提供独有的解决方案，帮助企业自动进行相关的政策匹配评估报告以及提升方向指导。我们助您顺利通过认定，享受更多政策支持。
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imageSources: ["lunbo1.png", "lunbo2.png", "lunbo3.png"],
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  height: 600px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  .divcon {
    width: 512px;
    height: 400px;
    padding: 8% 4% 2% 8%;
  }
  .Image {
    width: 100%;
    height: 299.9px;
  }
  .conDescribe {
    // width: 100%;
    padding: 8% 2% 8% 2%;
    P {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: #000;
    }
    span {
      padding: 1% 0% 1% 0%;
      display: inline-block;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #999999;
    }
    ul {
      height: 220px;
      width: 600px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px; /* WebKit */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #72d499; /* WebKit */
        border-radius: 4px; /* 圆角 */
      }

      &::-webkit-scrollbar-track {
        background-color: #f0fff3; /* WebKit */
        border-radius: 4px; /* 圆角 */
      }
      li {
        width: 576px;
      }
      span {
        margin: 1% 0% 1% 1%;
        padding: none;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;
      }
      p {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;
      }
    }
  }
}
</style>
