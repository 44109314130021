<template>
  <div class="top">
    <div class="topone">
      <!-- <img src="@/assets/SMART PLATE.png" alt="" /> -->
      <span>启迪之星国知</span>
      <ul>
        <li>
          <router-link to="/Home" :class="{ active: $route.path === '/Home' }"
            >首页</router-link
          >
        </li>
        <li>
          <router-link
            to="/Product"
            :class="{ active: $route.path === '/Product' }"
            >产品</router-link
          >
        </li>
        <li>
          <router-link
            to="/Client"
            :class="{ active: $route.path === '/Client' }"
            >客户案例</router-link
          >
        </li>
        <li>
          <router-link
            to="/AboutUs"
            :class="{ active: $route.path === '/AboutUs' }"
            >关于</router-link
          >
        </li>
      </ul>
    </div>
    <div class="toptwo">
      <div>
        <img src="@/assets/icon_phone.png" alt="" />
        <p>咨询电话：010-5723-8723</p>
      </div>

      <button>
        <!-- <a
          href="https://www.tusipo-test.cn/smartchain/#/Login"
          style="color: #ffffff"
        >
          进入我的服务平台
        </a> -->
        <!-- <a
          href="https://www.tusstarss.com/smartchain/#/login"
          style="color: #ffffff"
        >
          进入我的服务平台
        </a> -->
        <a
          href="https://test.tusstarss.com/smartchain/#/login"
          style="color: #ffffff"
        >
          进入我的服务平台
        </a>
      </button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.active {
  color: #333333; /* 高亮时的颜色，可以根据需要调整 */
  font-weight: bold; /* 可选：加粗字体 */
}
.top {
  width: 100vw;
  height: 64px;
  display: flex;
  background: #fcfbfb;
  justify-content: space-between;
  .topone {
    display: flex;
    span {
      // padding-right: 20px;
      padding: 22px 0px 0px 30px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
    }
    img {
      height: 14px;
      padding: 24px 10px 6px 24px;
    }
    ul {
      padding: 22px 12px 10px 24px;
      display: flex;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      li {
        cursor: pointer;
        margin-left: 68px;
      }
    }
  }
  .toptwo {
    display: flex;
    align-items: center;
    div {
      margin: 10px 34px 10px 15px;
      // padding: ;
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
        width: 18px;
        height: 18px;
      }
      p {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    button {
      width: 130px;
      height: 38px;
      border-radius: 4px;
      background-color: #72d499;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      margin-right: 40px;
      cursor: pointer;
    }
  }
}
</style>
