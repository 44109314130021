<template>
  <div class="rightdiv">
    <div class="leftdiv1">
      <span>启迪之星国知</span>
      <!-- <img src="@/assets/Group1.png" alt="" class="biaozhi" /> -->
    </div>
    <div>
      <ul>
        <li class="green">友情链接</li>
        <li>
          <a href="https://www.tusstar.com/" target="_blank">启迪之星孵化器</a>
        </li>
        <!-- <li>
          <a href="https://www.cnipa.gov.cn/" target="_blank"
            >中国国家知识产权局</a
          >
        </li> -->
        <!-- <li><a href="">中国专利查询网</a></li> -->
      </ul>
    </div>
    <div>
      <ul>
        <li class="green">联系信息</li>
        <li>全国统一服务热线：010-57238723</li>
        <li>官方邮箱：chenktotusstar.com</li>
        <li>大客户资讯专线：15681950831 ( 康婷)</li>

        <li>
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >京ICP备2023002503号-3</a
          >
        </li>
      </ul>
    </div>
    <div class="wx">
      <div>
        <p>公众号</p>
        <img src="@/assets/gzh.jpg" alt="" />
      </div>
      <div>
        <p>客服咨询</p>
        <img src="@/assets/khzx.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.biaozhi {
  width: 160px;
  height: 33px;
}
.rightdiv {
  display: flex;
  justify-content: space-evenly;
  width: 1440px;
  height: 240px;
  .leftdiv1 {
    display: flex;
    align-items: center;
    margin-top: 10px;
    span {
      margin-right: 10px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  ul {
    width: 300px;
    height: 120px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 60px;
    .green {
      color: #72d499;
    }
    li {
      margin-top: 10px;
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
    li:hover a {
      color: rgba(22, 119, 255, 1);
      text-decoration: underline;
    }
  }
  .wx {
    // width: 100px;
    display: flex;
    // align-items: center;
    margin-top: 50px;
    div {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      // display: flex;
      margin-left: 20px;
      img {
        margin-top: 10px;
        width: 120px;
        height: 120px;
      }
    }
  }
}
</style>
