<template>
  <div class="divContent">
    <div class="content">
      <div class="condiv">
        <div class="condivone">
          <span>法务AI助手</span>
          <p>
            智链法务AI助手是一款能针对在专利权，专利归属等专利领域通过智能对答，对提问者进行合理回答的产品。
          </p>
          <P>
            产品通过大数据算法，模型培养，能够对多数常见问题进行解答，并给出合理建议，解决一切专利相关的疑难问题。</P
          >
        </div>
        <div>
          <ul>
            <li>
              <div>
                <span>企业经营分析</span>
                <img src="@/assets/RightArrow.png" alt="" />
              </div>
            </li>
            <li>
              <div>
                <span>知识产权托管</span>
                <img src="@/assets/RightArrow.png" alt="" />
              </div>
            </li>
            <li>
              <div>
                <span>法务AI助手</span>
                <img src="@/assets/RightArrow.png" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="slideshowdiv">
        <img src="@/assets/Group83.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.divContent {
  background-color: #fafafa;
}
.content {
  // margin: 0% 2% 0% 2%;
  display: flex;
  // width: 1208px;
  // height: 480px;
  padding: 0% 0% 2% 0%;
  .condiv {
    padding: 40px 30px 27px 100px;
    .condivone {
      // display: flex;
      span {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
        display: inline-block;
        padding-bottom: 20px;
      }
      p {
        width: 568px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;
      }
    }
    ul {
      li {
        width: 568px;
        height: 64px;
        border-radius: 8px;
        background-color: #ffffff;
        border-radius: 8px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        div {
          margin-left: 6%;
          width: 88%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        span {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: #72d499;
        }
        img {
          width: 22px;
          height: 22px;
        }
      }
      li:hover {
        background: linear-gradient(
          90deg,
          rgba(114, 212, 153, 0.3) 0%,
          rgba(114, 212, 153, 0.1) 100%
        );
      }
    }
  }
  .slideshowdiv {
    margin-left: 8%;
    img {
      width: 600px;
      height: 472px;
    }
  }
}
</style>
